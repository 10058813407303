<template>
  <div id="app"></div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
@import "q-custom-elements/src/scss/loyalty.app.theme";
/*
#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/
</style>
