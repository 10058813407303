// import moment from 'moment'
import Vue from 'vue'
// import axios from 'axios' // mine
// import VueCustomElement from 'vue-custom-element' // mine
// import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

// import MyCustomElements from './custom-elements' // mine
import App from './App.vue'

// axios.defaults.xsrfCookieName = 'csrftoken' // mine
// axios.defaults.xsrfHeaderName = 'X-CSRFToken' // mine
// Vue.prototype.$http = axios // mine

Vue.config.productionTip = false

// Vue.use(VueGettext, {
//   callback: function (lang) {
//     moment.locale(lang)
//   }
// })
// Vue.use(VueCustomElement)


new Vue({
  render: h => h(App)
})//.$mount('#app') // uncomment for npm run serve

// MyCustomElements.init(Vue)
